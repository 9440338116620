import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AdConfig, CommunicationVisibility, HostedTrackingConfig } from "../../ts-interfaces/hosted-tracking-config";
import { Attachment, ItemList, OrderInterface } from "../../ts-interfaces/order-interface";
import { OrderTypeController } from '@deliverysolutions/order-status-mapping';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NullEmptyChecker } from "@deliverysolutions/utils";
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'ds-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, OnChanges {
  @Input() parentOrder!: OrderInterface; // in case we have multiple packages, this is the main parent order fetched from api
  @Input() order!: OrderInterface; // this is the package selected
  @Input() hostedTrackingConfig!: HostedTrackingConfig;
  @Input() isTerminalStatus!: boolean;
  @Input() isSystemStatus!: boolean;
  @Input() isSubscribeBoxVisible = false; // any default works
  @Input() isFeedbackBoxVisible = false; // any default works
  @Input() subscribeToUpdateOptions!: CommunicationVisibility;
  @Input() showSiblingOrders!: boolean;
  @Input() images: string[] = [];
  @Input() isNegativeStatus!: boolean;


  public orderTypeController = OrderTypeController;
  public packageItemList: ItemList[] = [];
  public secondaryAdsOne!: AdConfig | undefined;
  public secondaryAdsTwo!: AdConfig | undefined;
  public primaryAds: AdConfig | undefined;
  public adsToShow!: AdConfig | undefined;
  public isPackageItemBoxVisible = true;
  public returnsUrl: string | null = null;
  constructor(private modalService: NgbModal, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.secondaryAdsOne = this.hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.url && ads.type === "secondaryOne";
    });

    if (!this.secondaryAdsOne) {
      this.secondaryAdsTwo = this.hostedTrackingConfig.components.ads.find(ads => {
        return ads.active && ads.url && ads.type === "secondaryTwo";
      });
    }

    if (!this.secondaryAdsOne && !this.secondaryAdsTwo) {
      this.primaryAds = this.hostedTrackingConfig.components.ads.find(ads => {
        return ads.active && ads.type === "primary";
      });
    }

    this.adsToShow = this.secondaryAdsOne || this.secondaryAdsTwo || this.primaryAds;
    
    this.sharedService.isFeedbackSent$.subscribe(value => {
      this.order.feedback = {
        rating: value.rating,
        comment: value.comment ?? "",
        source: 'hosted-tracking'
      }
    });

    if (this.order.type === OrderTypeController.SHIPPING) {
      this.packageItemList = this.order.htChildOrdersDto?.[0]?.itemList ?? this.order.itemList ?? this.order.packages[0]?.itemList ?? [];
    } else {
      this.packageItemList = this.order.itemList ?? this.order.packages[0]?.itemList ?? [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const order = changes.order.currentValue;
    this.isPackageItemBoxVisible = this.hostedTrackingConfig.componentVisibility?.packageDetails?.isActiveItemCount ||
      this.hostedTrackingConfig.componentVisibility?.packageDetails?.isActiveItemDetails ||
      this.hostedTrackingConfig.componentVisibility?.packageDetails?.isActiveReturnsInitiation;
    this.packageItemList = order.itemList || order.packages[0]?.itemList || [];
    if (NullEmptyChecker.isNonEmptyArray(order.attachments)) {
      this.images = order.attachments.map((attachment: Attachment) => { return attachment.url });
    }
    if (this.order.status === "ORDER_DELIVERED") {
      this.returnsUrl = this.order.returnsUrl ? this.order.returnsUrl : null;
    }
  }

  openModal(content: any) {
    this.modalService.open(content, {
    });
  }

}
