<div class="order-card-container d-flex flex-column position-relative padding-for-16-9"
  [ngClass]="{'clickable': modalEnabled}"
  (click)="emitOpenModalEvent()">
  <div class="details-container p-3 center-absolutely">
    <div class="h-100 d-flex justify-content-between">
      <div class="status-date-time-box d-flex flex-column align-item-ends justify-content-between">
        <div class="tertiary-data" *ngIf="!timeToShow">{{ 'GENERIC.COMMON_PAGES.DELIVERY_DATE' | translate }} {{ 'GENERIC.COMMON_PAGES.UNAVAILABLE' | translate }}</div>
        <div class="tertiary-data" *ngIf="timeToShow">{{ 'GENERIC.COMMON_PAGES.DELIVERY_DATE' | translate }}</div>
        <div class="day-data " *ngIf="timeToShow">{{timeToShow.weekday}}</div>
        <div class="digit-data " *ngIf="timeToShow">{{timeToShow.day}}</div>
        <div class="" *ngIf="timeToShow">{{timeToShow.month}}</div>
      </div>
      <div class="status-update-box tracking-number-box d-flex flex-column align-items-end justify-content-between">
        <div>
          <span class="dsp-title f-12px">{{providerName}}</span>
          <img width="40" height="40"
            *ngIf="(!order.type || order.type === orderTypeController.SHIPPING || order.type === orderTypeController.DELIVERY || order.type === orderTypeController.IN_STORE_PICKUP || orderTypeController.CURBSIDE) && providerName"
            [src]="getDSPLogoURL(providerName)" alt="{{providerName + ' logo'}}" class="dsp-logo ml-1">
              <img width="40" height="40"
                *ngIf="(!order.type || order.type === orderTypeController.SHIPPING || order.type === orderTypeController.DELIVERY || order.type === orderTypeController.IN_STORE_PICKUP || orderTypeController.CURBSIDE) && (!order.provider && order.preferredProvider)"
                [src]="getDSPLogoURL(providerName)" alt="{{providerName + ' logo'}}" class="dsp-logo ml-1">
          <div class="tertiary-data"
            *ngIf="!order.type || order.type === orderTypeController.SHIPPING || order.type === orderTypeController.DELIVERY || order.type === orderTypeController.IN_STORE_PICKUP || orderTypeController.CURBSIDE">
            <span *ngIf="order.trackingNumber">{{ 'GENERIC.PLATFORM.TRACKING_NUMBER' | translate }}</span></div>
          <div class="secondary-data f-14"
            *ngIf="(!order.type || order.type === orderTypeController.SHIPPING || order.type === orderTypeController.DELIVERY || order.type === orderTypeController.IN_STORE_PICKUP || orderTypeController.CURBSIDE) && !order.providerTrackingUrl">
            {{order.trackingNumber}}</div>
          <div class="secondary-data f-14"
            *ngIf="(!order.type || order.type === orderTypeController.SHIPPING || order.type === orderTypeController.DELIVERY || order.type === orderTypeController.IN_STORE_PICKUP || orderTypeController.CURBSIDE) && order.providerTrackingUrl">
            <a href="{{order.providerTrackingUrl}}" target="_blank" (click)="$event.stopPropagation()">{{order.trackingNumber}}</a>
          </div>

        </div>
        <div class="order-status">
          <div class="status-data" [ngStyle]="{'color': getTerminalOrderStatus(order.status)}">{{orderStatusInEnglish}}
          </div>
          <div class="secondary-data" *ngIf="timeToShow || timeToShowOnStatus">
            {{beforeOrAfter}} {{timeToShow? timeToShow.time : timeToShowOnStatus}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="d-flex align-items-center flex-1-1-auto">
    <div class="secondary-data f-14 pl-3 ctooltip ctooltip-top" data-tooltip="Trying to find your package?"
      *ngIf="!order.type || order.type === orderTypeController.SHIPPING || order.type === orderTypeController.DELIVERY">
      Trying to find your package?
    </div>
    <div class="secondary-data f-14 pl-3 ctooltip ctooltip-top" data-tooltip="Have any issues making the pickup?"
      *ngIf="order.type && order.type !== orderTypeController.SHIPPING && order.type !== orderTypeController.DELIVERY">
      Have any issues making the pickup?
    </div>
  </div> -->
</div>
