import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackPageComponent } from './components/feedback-page/feedback-page.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { PreviewHostedFeedbackComponent } from './components/preview-hosted-feedback/preview-hosted-feedback.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ServiceUnavailableComponent } from "./components/service-unavailable/service-unavailable.component";
import { AuthGuard } from './services/auth-guard.service';
import { HostedFeedbackConfingResolver } from './services/resolvers/hosted-feedback-config.resolver';
import { HostedTrackingConfingResolver } from "./services/resolvers/hosted-tracking-config.resolver";
import { OrderFeedbackDetailsResolver } from './services/resolvers/order-feedback.resolver';
import { OrderDetailsResolver } from "./services/resolvers/order.resolver";

const routes: Routes = [
  {
    path: "feedback/:tenantId/:brandExternalId",
    resolve: {
      hostedFeedbackConfig: HostedFeedbackConfingResolver,
      orderDetails: OrderFeedbackDetailsResolver
    },
    component: FeedbackPageComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: "feedback/preview/:tenantId/:brandExternalId",
    component: PreviewHostedFeedbackComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: "feedback",
    resolve: {
      hostedFeedbackConfig: HostedFeedbackConfingResolver,
      orderDetails: OrderFeedbackDetailsResolver
    },
    component: FeedbackPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ":tenantId/:brandExternalId",
    resolve: {
      hostedTrackingConfig: HostedTrackingConfingResolver,
      orderDetails: OrderDetailsResolver
    },
    component: HomeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: "preview/:tenantId/:brandExternalId",
    component: PreviewComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  { path: 'service-unavailable', component: ServiceUnavailableComponent },
  {
    path: "",
    resolve: {
      hostedTrackingConfig: HostedTrackingConfingResolver,
      orderDetails: OrderDetailsResolver
    },
    component: HomeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
