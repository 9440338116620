<!-- Header section start -->
<div class="nav-cnt" *ngIf="hostedTrackingConfig">
  <div class="container-fluid max-950px">
    <div class="row">
      <div class="col">
        <ds-site-header [header]="hostedTrackingConfig.components.header!"
          [businessLogos]="hostedTrackingConfig?.branding?.businessLogos!" [isBackgroundWhite]="isNavBackgroundWhite" [captureEvent]="'click-hosted-tracking-navigation'">
        </ds-site-header>
      </div>
    </div>
  </div>
</div>
<!-- Header section end -->

<!-- Broadcast section start -->
<div class="container-fluid max-950px">
  <div class="row">
    <div class="col">
      <ds-broadcast-message *ngIf="hostedTrackingConfig?.components?.broadcast?.active"
        [hostedTrackingConfig]="hostedTrackingConfig!" [component]="'hosted-tracking'"></ds-broadcast-message>
    </div>
  </div>
</div>
<!-- Broadcast section end -->

<!-- package navigation start -->
<div class="container-fluid max-950px">
  <div class="row"
    *ngIf="this.fetchedOrder && this.childOrderList.length && (this.fetchedOrder.isParent || this.fetchedOrder.hasOwnProperty('groupId'))">
    <div class="col">
      <div class="packages-container d-flex mx-n3 mx-sm-0 align-items-stretch">
        <nav class="tabbable flex-grow-1">
          <ul class="nav nav-tabs m-0 p-0 flex-nowrap" id="nav-tab" role="tablist">
            <li *ngFor="let childOrder of childOrderList; index as i"
              class="nav-item d-inline-flex cursor-pointer first-row py-2 px-3 py-md-3 px-md-4 flex-column flex-md-row"
              data-toggle="tab" (click)="selectShippingPackage(i)" role="tab" aria-controls="nav-package1"
              aria-selected="true" [ngClass]="{'active':i===activeChildOrderIndex, 'd-md-none': i>2}">
              <div *ngIf="childOrder?.type === orderTypeController.SHIPPING" class="d-inline-block">
                <img class="svg-img d-inline-block" src="/assets/icons/shipping-truck-black.svg"
                  *ngIf="i!==activeChildOrderIndex">
                <img class="svg-img d-inline-block" src="/assets/icons/shipping-truck-white.svg"
                  *ngIf="i===activeChildOrderIndex">
              </div>
              <div *ngIf="childOrder?.type === orderTypeController.DELIVERY" class="d-inline-block">
                <img class="img d-inline-block" src="/assets/icons/Delivery-32px.svg" *ngIf="i!==activeChildOrderIndex">
                <img class="img d-inline-block" src="/assets/icons/delivery-white.svg"
                  *ngIf="i===activeChildOrderIndex">
              </div>
              <div *ngIf="childOrder?.type === orderTypeController.CURBSIDE" class="d-inline-block">
                <img class="img d-inline-block" src="/assets/icons/Curbside-32px.svg" *ngIf="i!==activeChildOrderIndex">
                <img class="img d-inline-block" src="/assets/icons/Curbside-white.svg"
                  *ngIf="i===activeChildOrderIndex">
              </div>
              <div *ngIf="childOrder?.type === orderTypeController.IN_STORE_PICKUP" class="d-inline-block">
                <img class="img d-inline-block" src="/assets/icons/Bopis-32px.svg" *ngIf="i!==activeChildOrderIndex">
                <img class="img d-inline-block" src="/assets/icons/bopis-white.svg" *ngIf="i===activeChildOrderIndex">
              </div>
              <span class="d-inline-block text ml-2" [captureEvent]="'view-package-details'">
                {{ 'GENERIC.PLATFORM.PACKAGE' | translate }} {{i+1}}
              </span>
            </li>

            <!-- overflow menu for desktop when no. of packages are more than 3 -->
            <div
              class="overflow-menu-cnt d-none d-md-inline-flex align-items-center cursor-pointer ml-4 position-relative"
              *ngIf="remainingPackages.length"
              (click)="overflowMenu.classList.toggle('d-none'); $event.stopImmediatePropagation()">
              <span class="dot pointer-events-none"></span>
              <span class="dot ml-1 pointer-events-none"></span>
              <span class="dot ml-1 pointer-events-none"></span>

              <ul #overflowMenu class="m-0 p-0 position-absolute overflow-menu flex-column d-none">
                <li class="nav-item d-inline-flex py-2 px-3 py-md-3 px-md-4 flex-column flex-md-row"
                  *ngFor="let childOrder  of remainingPackages; index as i" data-toggle="tab" role="tab"
                  (click)="selectShippingPackage(i+3)" [ngClass]="{'active':(i+3)===activeChildOrderIndex}">
                  <div *ngIf="childOrder?.type === orderTypeController.SHIPPING" class="d-inline-block">
                    <img class="svg-img d-inline-block" src="/assets/icons/shipping-truck-black.svg"
                      *ngIf="(i+3)!==activeChildOrderIndex">
                    <img class="svg-img d-inline-block" src="/assets/icons/shipping-truck-white.svg"
                      *ngIf="(i+3)===activeChildOrderIndex">
                  </div>
                  <div *ngIf="childOrder?.type === orderTypeController.DELIVERY" class="d-inline-block">
                    <img class="img d-inline-block" src="/assets/icons/Delivery-32px.svg"
                      *ngIf="(i+3)!==activeChildOrderIndex">
                    <img class="img d-inline-block" src="/assets/icons/delivery-white.svg"
                      *ngIf="(i+3)===activeChildOrderIndex">
                  </div>
                  <div *ngIf="childOrder?.type === orderTypeController.CURBSIDE" class="d-inline-block">
                    <img class="img d-inline-block" src="/assets/icons/Curbside-32px.svg"
                      *ngIf="(i+3)!==activeChildOrderIndex">
                    <img class="img d-inline-block" src="/assets/icons/curbside-white.svg"
                      *ngIf="(i+3)===activeChildOrderIndex">
                  </div>
                  <div *ngIf="childOrder?.type === orderTypeController.IN_STORE_PICKUP" class="d-inline-block">
                    <img class="img d-inline-block" src="/assets/icons/Bopis-32px.svg"
                      *ngIf="(i+3)!==activeChildOrderIndex">
                    <img class="img d-inline-block" src="/assets/icons/bopis-white.svg"
                      *ngIf="(i+3)===activeChildOrderIndex">
                  </div>
                  <span class="d-inline-block text ml-2 text-nowrap">{{ 'GENERIC.PLATFORM.PACKAGE' | translate }} {{i+4}}</span>
                </li>
              </ul>
            </div>
          </ul>
        </nav>
        <div class="view-all"
          *ngIf="this.fetchedOrder && this.childOrderList.length && (this.fetchedOrder.isParent || this.fetchedOrder.hasOwnProperty('groupId'))">
          <button class="btn py-3 px-4 h-100" (click)="toggleViewallSidebar()" [captureEvent]="'click-view-all-packages'">
            {{ 'GENERIC.PLATFORM.VIEW_ALL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- package navigation end -->

<!-- order details -->
<div class="container-fluid max-950px" *ngIf="fetchedOrder && order && !isSystemStatus">
  <ds-order-details [order]="order" [hostedTrackingConfig]="hostedTrackingConfig!" [isTerminalStatus]="isTerminalStatus"
    [isSystemStatus]="isSystemStatus" [parentOrder]="fetchedOrder" [isSubscribeBoxVisible]="isSubscribeBoxVisible"
    [isFeedbackBoxVisible]="isFeedbackBoxVisible" [subscribeToUpdateOptions]="subscribeToUpdateVisibility"
    [showSiblingOrders]="showSiblingOrders" [isNegativeStatus]="isNegativeStatus"></ds-order-details>
</div>

<!-- order info unavailable when status is 'system' - start -->
<div class="container-fluid max-950px my-4" *ngIf="order && isSystemStatus">
  <div class="row">
    <h1 *ngIf="showSiblingOrders" class="col orderIdTitle my-4">
      {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.groupId}} ( {{ 'GENERIC.PLATFORM.PACKAGE' | translate }} #{{order.orderExternalId}} )
    </h1>
    <h1 *ngIf="!showSiblingOrders" class="col orderIdTitle my-4">
      {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.orderExternalId}}
    </h1>
  </div>

  <div class="row">
    <div class="col">
      <app-compliance-info-box-section *ngIf="hostedTrackingConfig?.componentVisibility?.showComplianceInfo" [order]="order"
        [hostedTrackingConfig]="hostedTrackingConfig!"></app-compliance-info-box-section>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <ds-info-unavailable></ds-info-unavailable>
    </div>
    <div class="col-12 col-md-6 mt-3 mt-md-0">
      <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
    </div>
  </div>
</div>
<!-- order info unavailable when status is 'system' - end -->

<!-- order not found - start  -->
<div class="container-fluid max-950px my-4" *ngIf="!order">
  <div class="row">
    <div class="custom-col-12 custom-col-md-6">
      <ds-info-unavailable></ds-info-unavailable>
    </div>
    <div class="custom-col-12 custom-col-md-6 mt-3 mt-md-0">
      <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
    </div>
  </div>
</div>
<!-- order not found - end  -->

<!-- recommendations start -->
<div class="container-fluid max-950px pb-14px" *ngIf="recommendations && recommendations.length">
  <div class="row">
    <div class="col">
      <ds-recommendations [recommendations]="recommendations"></ds-recommendations>
    </div>
  </div>
</div>
<!-- recommendations end-->


<!-- Second ad banner start -->
<div class="container-fluid max-950px" *ngIf="secondaryAdsOne || secondaryAdsTwo">
  <div class="row">
    <div class="col">
      <ds-wide-ads-banner [secondaryAdsOne]="secondaryAdsOne" [secondaryAdsTwo]="secondaryAdsTwo"
        [component]="'hosted-tracking'"></ds-wide-ads-banner>
    </div>
  </div>
</div>
<!-- Second ad banner end-->

<!-- footer start-->
<div class="container-fluid max-950px mt-3 mb-5">
  <div class="row">
    <div class="col">
      <ds-site-footer [order]="order" [tenantId]="hostedTrackingConfig?.tenantId"></ds-site-footer>
    </div>
  </div>
</div>
<!-- footer end-->


<!-- Need Help start -->
<a class="need-help-section f-16" [href]="hostedTrackingConfig?.components?.needHelp?.helpUrl"
  *ngIf="hostedTrackingConfig?.components?.needHelp?.helpUrl" target="_blank" [captureEvent]="'click-hosted-tracking-help-menu'">
  <span class="question-mark">?</span>&nbsp;<span class="needhelp-txt">{{
    hostedTrackingConfig?.components?.needHelp?.displayText || ('HOSTED_FEEDBACK.FEEDBACK_COMPONENT.NEED_HELP' | translate) }}</span>
</a>
<!-- Need Help end -->



<!-- 'view all' package list sidebar -->
<div
  *ngIf="this.fetchedOrder && this.childOrderList.length && (this.fetchedOrder.isParent || this.fetchedOrder.hasOwnProperty('groupId'))"
  style="display: none !important;" class="view-all-sidebar-cnt position-fixed" id="shipment-viewall-sidebar">
  <div style="display: none;" class="sidebar-backdrop w-100 h-100 position-absolute"></div>

  <div class="h-100 overflow-y-auto" (click)="toggleViewallSidebar()">
    <!-- actual package list ui -->
    <div class="view-all-sidebar w-100 overflow-auto translateX100p" (click)="$event.stopImmediatePropagation()">
      <!-- order title -->
      <div class="sidebar-title-cnt d-flex">
        <h2 class="sidebar-title text-ellipsis m-0 px-3 py-3 flex-grow-1 flex-shrink-1">
          <span *ngIf="this.fetchedOrder.hasOwnProperty('groupId') && showSiblingOrders">#{{order?.groupId}}</span>
          <span *ngIf="!this.fetchedOrder.hasOwnProperty('groupId') && !showSiblingOrders">{{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }}
            #{{fetchedOrder?.orderExternalId}}</span>
        </h2>
        <button class="btn mx-4" type="button" style="color: inherit;" (click)="toggleViewallSidebar()">✕</button>
      </div>
      <!-- order title end -->

      <!-- package info -->
      <div class="package-cnt mb-5 mt-3" *ngFor="let childOrder of childOrderList; index as index">
        <!-- package title -->
        <div class="title-cnt mb-4">
          <h3 class="width-fit-content pt-3 px-4 ml-3">
            <img *ngIf="childOrder?.type === orderTypeController.SHIPPING" class="img"
              src="/assets/icons/shipping-truck-black-viewAll.svg">
            <img *ngIf="childOrder?.type === orderTypeController.DELIVERY" class="img" src="/assets/icons/Delivery-32px.svg">
            <img *ngIf="childOrder?.type === orderTypeController.CURBSIDE" class="img" src="/assets/icons/Curbside-32px.svg">
            <img *ngIf="childOrder?.type === orderTypeController.IN_STORE_PICKUP" class="img" src="/assets/icons/Bopis-32px.svg">
            <span>{{ 'GENERIC.PLATFORM.PACKAGE' | translate }} {{index+1}}</span>
          </h3>
        </div>
        <!-- package title end -->
        <!-- package meta info -->
        <div class="meta-cnt px-3 mb-3 d-flex">
          <div class="left w-50">
            <div *ngIf="deliveryTimeOfChildOrders[index]">
              {{deliveryTimeOfChildOrders[index] | date:'EEEE': childOrder.timeZone}}</div>
            <div *ngIf="deliveryTimeOfChildOrders[index]">
              {{deliveryTimeOfChildOrders[index] | date: undefined : childOrder.timeZone}}</div>
            <div class="order-status-text">
              {{statusGroup.getDisplayName(childOrder.status, childOrder.type.toLowerCase()) || "N/A"}}</div>
          </div>
          <div class="right w-50 text-right">
            <div *ngIf="childOrder.providerName"><img width="40" height="40" [src]="getDSPLogoURL(childOrder.providerName)"
                alt="{{childOrder.providerName + ' logo'}}" class="dsp-logo"></div>
            <div *ngIf="!childOrder.provider && childOrder.providerName"><img width="40" height="40"
                [src]="getDSPLogoURL(childOrder.providerName)" alt="{{childOrder.preferredProvider + ' logo'}}"
                class="dsp-logo"></div>
            <div><small>{{ 'GENERIC.PLATFORM.TRACKING_NUMBER' | translate }}</small></div>
            <div *ngIf="!childOrder.providerTrackingUrl">{{childOrder.trackingNumber}}</div>
            <a class="tracking-url" target="_blank" rel="noopener" *ngIf="childOrder.providerTrackingUrl"
              [href]="childOrder.providerTrackingUrl" [captureEvent]="'click-tracking-number'">{{childOrder.trackingNumber}}</a>
          </div>
        </div>
        <!-- package meta info end -->

        <!-- package items -->
        <div class="px-3 package-item-cnt" *ngIf="childOrder.packages[0]?.itemList?.length || childOrder.itemList?.length">
          <div class=" d-flex mb-md-4 mb-3 pr-3 position-relative"
            *ngFor="let item of (childOrder.itemList || childOrder.packages[0]?.itemList)">

            <div *ngIf="item.description"
              class="item-description-info position-absolute width-fit-content  ctooltip ctooltip-left"
              [attr.data-tooltip]="item.description" style="top:0;right:0">
              <span>&#9432;</span>
            </div>

            <!-- left side -->
            <div style="width: 100px; height: 100px;">
              <img class="flex-grow-0 flex-shrink-0 package-image"
                src="{{item.image || '/assets/icons/package-item-placeholder.svg'}}" [alt]="item.title">
            </div>

            <!-- right side -->
            <div class="flex-fill pl-3 min-width-0">
              <div class="w-100 f-18px" *ngIf="item.title">
                {{item.title}}
              </div>
              <div class="f-14px" *ngIf="item.sku">
                <span class="font-weight-light">{{ 'GENERIC.PLATFORM.SKU_TEXT' | translate }}:</span> {{item.sku}}
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <div class="details f-14px width-fit-content" *ngIf="item.quantity">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.QUANTITY_TEXT' | translate }}:</span> {{item.quantity}}
                </div>
                <div class="details f-14px width-fit-content"
                  *ngIf="item.sale_price !== undefined && item.price !== undefined">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.PRICE_TEXT' | translate }}:</span>
                  <span>&nbsp;${{item.sale_price}}</span>&nbsp;
                  <span style="text-decoration: line-through;" class="f-12px">${{item.price}}</span>
                </div>
                <div class="details f-14px width-fit-content"
                  *ngIf="(item.sale_price === undefined && item.price !== undefined) || (item.sale_price !== undefined && item.price === undefined)">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.PRICE_TEXT' | translate }}:</span>${{item.price || item.sale_price}}
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <div class="details f-14px width-fit-content" *ngIf="item.weight">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.WEIGHT_TEXT' | translate }}:</span> {{item.weight}} lbs
                </div>
                <div class="details f-14px width-fit-content" *ngIf="item.size">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.SIZE_TEXT' | translate }}:</span> {{item.size.length}} in x {{item.size.width}} in x {{item.size.height}} in
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- package items end -->

        <div class="px-3" *ngIf="!childOrder.itemList?.length && !childOrder.packages[0]?.itemList?.length">
          {{ 'GENERIC.PLATFORM.NUMBER_OF_ITEMS' | translate }}: {{childOrder.packages[0]?.items}}
        </div>
      </div>
      <!-- package info end -->

      <div>
        <ds-subscribe-to-updates *ngIf="fetchedOrder && isSubscribeBoxVisible" [orderIdList]="childOrderIdList"
          [orderType]="fetchedOrder.type" [subscribeToUpdateOptions]="subscribeToUpdateVisibility">
        </ds-subscribe-to-updates>
      </div>
      <div class="mt-3" *ngIf="isFeedbackBoxVisible">
        <ds-feedback-box [orderIdList]="childOrderIdList" [orderType]="fetchedOrder.type"
          [isTerminalStatus]="isTerminalStatus" source="hosted-tracking">
        </ds-feedback-box>
      </div>
    </div>
  </div>
</div>

<ds-cookie-consent *ngIf="order" [order]="order"></ds-cookie-consent>